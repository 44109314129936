import * as React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Seo from "../components/Seo";

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
	<div className="main--centerwrapper">
		<StaticImage
		src="../images/photos/pressRes/rebecca1.jpg"
		alt="Jesse Rivest press image"
		placeholder="blurred"
		layout="constrained"
		className="main--centered"
		imgClassName="img--bordered-small"
		width={500}
		/>
	</div>
	<h3>404: Not Found</h3>
    <p>Sorry, that route doesn't exist, i.e., page not found.</p>
	<p>
		<Link to="/">Go to the homepage</Link>
	</p>
  </Layout>
);

export default NotFoundPage;
